import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca root`}</strong>{` -- download and validate the root certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca root [<root-file>]
[--ca-url=<uri>] [--fingerprint=<fingerprint>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca root`}</strong>{` downloads and validates the root certificate from the
certificate authority.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`root-file`}</inlineCode>{`
File to write root certificate (PEM format)`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--fingerprint`}</strong>{`=`}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{`
The `}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{` of the targeted root certificate.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Get the root fingerprint in the CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint /path/to/root_ca.crt
0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
`}</code></pre>
    <p>{`Download the root certificate from the configured certificate authority:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca root root_ca.crt \\
  --fingerprint 0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
`}</code></pre>
    <p>{`Download the root certificate using a given certificate authority:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca root root_ca.crt \\
  --ca-url https://ca.smallstep.com:9000 \\
  --fingerprint 0d7d3834cf187726cf331c40a31aa7ef6b29ba4df601416c9788f6ee01058cf3
`}</code></pre>
    <p>{`Print the root certificate using the flags set by `}<inlineCode parentName="p">{`step ca bootstrap`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca root
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      